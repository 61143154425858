/* Navbar */
.theme-bg-color {
  background: linear-gradient(96deg, #b69c28 3.46%, #bb9800 108.85%);
}

.theme-text-color {
  color: #bb9800;
}

.text-case {
  text-transform: "capitalize";
}

.video-container {
  width: 100%; /* Adjust as per your layout */
  max-width: auto; /* Example max-width */
  margin: 0 auto; /* Center the container */
}

.video-container video {
  width: 100%; /* Ensure the video fills its container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove default inline behavior */
}

.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
  height: 50px !important;
  width: 50px !important;
  margin: auto !important;
}

.link-decoration {
  text-decoration: none;
  color: black !important;
  font-weight: 500;
}

.link-sidebar {
  text-decoration: none;
  color: #bb9800;
  font-weight: 500;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.6) 55px 55px 450px 450px inset;
}

.hover-btn:hover {
  background-color: #bb9800 !important;
  color: white;
  border: 1.5px dashed white;
}

.bg-gradiant {
  background: linear-gradient(90deg, #ffffff 20%, #e3a02c 100%);
  box-shadow: rgba(0, 0, 0, 0.6) 55px 55px 450px 450px inset;
  color: white;
  background-image: url(./images/diwali.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

/* .footer-container { 
color:"#dde7ee"
} */

.cloud-bg {
  background-image: url("./components/Pages/RBFiles/cloud.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
