@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Helvetica:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Segoe+UI:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}

/* Sticky Navbar */
.sticky-navbar {
  position: sticky;
  position: -webkit-sticky !important; /* For older Safari versions */
  top: 0; /* or another value depending on where you want it to stick */
  z-index: 1000; /* Ensures it's on top of other elements */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Sticky Navbar */

.css-1f06d2y.Mui-focused {
  color: black !important;
}

/* Contact Seciton */
.card-banner .contact-form-container {
  padding-right: 80px;
  margin: 30px auto 100px auto !important;
}

.card-banner .contact-form,
.card-banner .contact-form-container {
  width: 100%;
}

.card-banner .contact-details {
  width: 600px;
  height: 380px !important;
  /* right: 0; */
  margin: 230px 0px 0px -65px !important;
}

/*  ############# New CSS Line #############   */
.logo-header-inner img {
  width: 80px;
  height: 80px;
  margin: 5px;
}

.container-margin-top {
  margin-top: 0px;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 80px !important;
}

@media (min-width: 600px) {
  .css-i6s8oy {
    min-height: 90px !important;
  }
}

.css-1r9jet7 {
  margin-bottom: 10px;
}

.sign-up-gradiant {
  background: linear-gradient(90deg, #e2c465 20%, #e3a02c 100%) !important;
  /* background: #e2c465 !important; */
  border-radius: 20px !important;
  padding: 5px 15px !important;
  font-weight: 700 !important;
  border: 2px solid #e2c465 !important;
}

.contact-toolbar-icons {
  display: none !important;
}

.anchor-button,
.see-more {
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  color: black !important;
}

.card-banner .button {
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(90deg, #daba5c 20%, #e3a02c 100%);
  color: white;
  outline: none !important;
  border: none !important;
  padding: 18px 15px;
  font-weight: bold;
}

.struct .button {
  width: 100%;
  border-radius: 25px;
  background: linear-gradient(90deg, #daba5c 20%, #e3a02c 100%);
  color: white;
  outline: none !important;
  border: none !important;
  padding: 5px 15px !important;
  font-weight: 600;
}

.work-packages-ul {
  margin-top: 20px;
}

.card-banner .action-button {
  border-radius: 25px;
  outline: none !important;
  /* border: none !important; */
  padding: 15px;
  font-weight: 600;
  margin: auto 5px;
  box-shadow: wheat;
}

.action-btn {
  cursor: pointer;
}

.card-banner .action-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-banner .whatsaap-button {
  color: green;
}

.card-banner .download-button {
  color: black;
}

.pkg-icon-manage {
  cursor: pointer;
  mix-blend-mode: multiply;
  height: 20px !important;
  width: 20px !important;
  background-color: transparent;
  margin: 5px;
}

.home-offer-banner {
  width: 100%;
  /* height: 125px !important; */
  margin: 20px auto;
}

.home-offer-banner img {
  /* height: 125px !important; */
  width: 100%;
  user-select: none;
}

.struct .titlefigts {
  color: #e3a02c;
}

.struct .title-work {
  color: #e3a02c;
}

.plain-bg {
  background: linear-gradient(90deg, #daba5c 20%, #e3a02c 100%);
}

.team-container {
  background-color: #432223;
  padding: 20px 10px;
}

.review-container {
  background-color: white;
  padding: 20px 10px;
}

.flip-card-section {
  background-color: #f6ecd0;
  padding: 20px;
}

.architect-banner {
  display: block !important;
}

.card-banner .css-1vwp0dg-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: black !important;
}

.card-banner .css-102ng7x-MuiPaper-root-MuiCard-root {
  width: 350px !important;
}

/*-------------------------------------
	 owl button vertical to center
-------------------------------------*/
.owl-carousel.owl-btn-vertical-center .owl-nav {
  margin: 0px;
}

.owl-carousel .owl-nav button.owl-prev {
  padding: 15px !important;
  background-color: white !important;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  padding: 40px !important;
  background-color: white !important;
  /* border: 1px solid !important; */
  color: #e3a02c !important;
  font-size: 35px !important;
  margin: 10px !important;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  position: absolute !important;
  top: 50% !important;
  margin: -30px 0 0 0 !important;
  background-color: white !important;
  padding: 15px !important;
}

.owl-carousel .owl-nav button.owl-prev {
  left: 0 !important;
}

.owl-carousel .owl-nav button.owl-next {
  right: 0 !important;
}

.section-content {
  margin-top: 40px !important;
  /* border-top: 5px solid; */
}

.container-section {
  padding: 10px;
}

.home-caraousal-banner .owl-carousel .owl-item {
  animation-duration: 1s;
}

.home-caraousal-banner .owl-carousel .owl-item.active {
  animation: slideInLeft 1s forwards;
}

.home-caraousal-banner .owl-carousel .owl-item:not(.active) {
  animation: slideOutLeft 1s forwards;
}

/* Home slider */
@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.card-banner .contact-card {
  background-color: white !important;
  height: 600px;
  padding: 20px;
}

.experties-component {
  max-width: 450px;
  border-radius: 15px;
  padding: 15px;
  height: 170px;
}

.border-top-5 {
  border-top: 2px solid white;
  padding-top: 10px;
}

/* .team-container .team-card {
  width: 450px !important;
} */

.team-card {
  height: 260px;
}

/* Footer Section Start*/
.footer-top {
  margin-top: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.footer-top .footer-container {
  background: linear-gradient(356deg, rgb(66 60 47) 0%, rgb(91 91 91) 100%);
}

.footer-top .opacity-08 {
  opacity: 0.8;
}

.footer-container .footer-child {
  padding: 10px;
}

.footer-container .social-icons-link {
  text-decoration: none;
  height: 40px;
  width: 40px;
  color: black;
  border-radius: 50%;
  margin: auto 5px;
  padding: 10px 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.footer-container .ul-head {
  color: #e3a02c;
}

.footer-container .social-icons-link:hover {
  background-color: #e3a02c;
  color: white;
}

.footer-container .link-color {
  color: white;
  text-decoration: none;
}

.footer-container .link-color svg {
  margin-right: 10px;
  color: #e3a02c;
}

.footer-container .link-color:hover {
  color: #e3a02c;
}

.footer-container .social-icons-link:hover {
  background-color: #e3a02c;
  color: white;
}

.footer-container .footer-bottom {
  padding: 16px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  /* color: #787878;
  font-size: 13px;
  background-color: #23252d; */
  background-color: #ce9e48 !important;
  color: white;
  font-size: 16px;
  justify-content: center;
}

.link-sidebar {
  text-decoration: none;
  /* color: #e3a02c; */
  color: #422321;
  font-weight: 500;
}

.overlay-wraper {
  position: relative;
}

.sx-footer-bot-left {
  padding-left: 15px;
  padding-right: 15px;
}

.sx-footer-bot-left {
  float: left;
}

.overlay-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.sx-bnr-inr-entry {
  display: table-cell;
  vertical-align: bottom;
  text-align: left;
  position: relative;
  z-index: 1;
  vertical-align: bottom;
  width: 100%;
  padding-bottom: 10px;
}

.sx-bnr-inr-entry .banner-title-outer {
  position: relative;
  margin-bottom: 20px;
}

.sx-bnr-inr-entry .banner-title-outer h2 {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 0px;
}

.sx-bnr-inr-entry .banner-title-outer p {
  color: #fff;
  max-width: 555px;
  width: 100%;
}

.card-banner .contact-icon-container {
  margin-top: 10px !important;
}

.card-banner .margin-align {
  margin: 0px 0px 0px 15px !important;
  padding: 0px;
  text-decoration: none;
  color: black !important;
}

.card-banner .margin-align li {
  font-size: 15px;
  margin: 2px auto;
  padding-top: 0px;
  padding-bottom: 0px;
}

.card-banner .margin-icon-align {
  margin-right: 8px !important;
}

.img-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 5px;
}

.img-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  /* background-color: rgba(52, 152, 219, 0.5); */
  background-color: rgba(250, 215, 139, 0.5); /* Adjust the color and opacity */
  transition: left 0.5s ease;
  z-index: 999;
}

.card-banner .input-f-width {
  width: 280px !important;
}

.hover-img {
  color: white;
  font-size: 20px;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 1;
  transform: translateX(0);
}

.img-container:hover .hover-img {
  transform: scale(1.2);
  opacity: 1;
}

.img-container:hover::before {
  left: 0;
}

/* What We Do */
.card-banner .what-we-do-container .what-we-do-pipe {
  height: 25px;
  width: 2px;
  padding: 1px;
  background-color: black;
  transition: all 0.8s ease-in-out;
}

.card-banner .what-we-do-container .what-we-do-container-count {
  color: #e3a02c !important;
  font-weight: bold;
  justify-content: center;
  width: 100%;
  display: flex;
  margin-left: -18px;
  position: absolute;
  z-index: 999 !important;
}

.card-banner .what-we-do-card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.card-banner .what-we-do-container:hover .what-we-do-container-count {
  animation: titleanimate 0.8s ease-in-out;
}

@keyframes titleanimate {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* What We do End */

/*| line=====================*/

.our-story {
  position: relative;
  z-index: 0;
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px dashed #404040;
  padding-bottom: 25px;
  background-color: #1d1f26;
  padding: 20px;
}

.our-story:last-child {
  margin-bottom: 0px;
}

.our-story-detail {
  margin-left: 40px;
}

.our-story-time {
  min-width: 30px;
}

.our-story-detail p {
  margin-bottom: 0px;
}

.our-story-time .date {
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
}

.our-story-time .month {
  display: block;
}

.our-story-year {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #fff;
  margin-bottom: 0px;
  color: #e3a02c;
}

.experience .our-story {
  position: relative;
  z-index: 0;
  display: flex;
  margin-bottom: 30px;
  border: 1px dashed #ddd;
  padding-bottom: 25px;
  background-color: #fff;
  padding: 20px;
}

/* Page not found start */

/*--------------------------------
	page-notfound
-----------------------------------*/
.page-notfound strong {
  display: block;
  font-size: 24px;
  margin-bottom: 20px;
}

.page-notfound span.title {
  position: relative;
  display: block;
  color: #e3a02c;
  font-size: 45px;
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 13px 0 14px;
}

.page-notfound p {
  font-size: 18px;
}

/* Ribbon Strip start */
.ribbon {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */

  position: absolute;
  top: 10px;
  padding-inline: 12px;
  background: #e3a02c;
  border-bottom: var(--f) solid #0005;
  color: #fff;
  line-height: 1.5em;
  font-family: sans-serif;
  font-size: 20px !important;
}

.left {
  left: calc(-1 * var(--f));
  border-right: var(--r) solid #0000;
  clip-path: polygon(
    100% 0,
    0 0,
    0 calc(100% - var(--f)),
    var(--f) 100%,
    var(--f) calc(100% - var(--f)),
    100% calc(100% - var(--f)),
    calc(100% - var(--r)) calc(50% - var(--f) / 2)
  );
}
/* .right {
  right: calc(-1 * var(--f));
  border-left: var(--r) solid #0000;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
} */

/* Ribbon Strip end */

@media only screen and (max-width: 767px) {
  .page-notfound {
    text-align: center;
  }
}

/* Page not found end*/

@media only screen and (max-width: 640px) {
  .our-story {
    display: block;
  }

  .our-story .our-story-info {
    display: block !important;
    justify-content: inherit !important;
    margin-bottom: 10px;
  }

  .our-story-year {
    border-left: 0px;
    padding-left: 0px;
    margin-left: 0px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
  }

  .our-story-time {
    min-width: auto;
    display: inline-block;
  }

  .our-story-time .date,
  .our-story-time .month {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
  }

  .our-story-time .date {
    padding-right: 3px;
  }

  .our-story-detail {
    margin-left: 0px;
  }

  .section-content .service-card-container {
    width: 400px !important;
    transition: all 0.5s ease-in-out;
  }
}

/*-------------------------------------
	Title separators 
-------------------------------------*/
.sx-separator-outer {
  overflow: hidden;
}

.sx-separator {
  display: inline-block;
  margin-top: 0px;
  border: 1px solid #ebebeb;

  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.sep-line-one {
  position: relative;
}

.sep-line-one:after,
.sep-line-one:before {
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.sep-line-one:after,
.sep-line-one:before {
  position: absolute;
  content: "";
  background-color: #000;
  height: 4px;
}

.sep-line-one:after {
  width: 20px;
  right: -40px;
  top: 50%;
  margin-top: -2px;
}

.sep-line-one:before {
  width: 20px;
  left: -40px;
  top: 50%;
  margin-top: -2px;
}

.section-head:hover .sep-line-one:after {
  width: 35px;
}

.section-head:hover .sep-line-one:before {
  width: 35px;
}

/*Separator defualt*/

.sx-separator-outer.separator-left {
  text-align: left;
}

.sx-separator-outer.separator-right {
  text-align: right;
}

.sx-separator-outer.separator-center {
  text-align: center;
}

.sx-separator-outer.separator-left .sep-line-one {
  padding: 20px 60px 20px 20px;
  font-size: 25px;
  font-weight: 800;
}

.sx-separator-outer.separator-left .sep-line-one:after {
  width: 20px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
}

.sx-separator-outer.separator-right .sep-line-one {
  padding: 20px 20px 20px 60px;
  font-size: 42px;
  font-weight: 800;
}

.sx-separator-outer.separator-right .sep-line-one:before {
  width: 20px;
  left: 20px;
  top: 50%;
  margin-top: -2px;
}

.sx-separator-outer.separator-center .sep-line-one {
  padding: 10px 60px;
  font-size: 25px;
  font-weight: 800;
}

.sx-separator-outer.separator-center .sep-line-one:before {
  width: 20px;
  left: 20px;
  top: 50%;
  margin-top: -2px;
}

.sx-separator-outer.separator-center .sep-line-one:after {
  width: 20px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
}

.section-head:hover .sx-separator-outer .sep-line-one:after {
  width: 35px;
}

.section-head:hover .sx-separator-outer .sep-line-one:before {
  width: 35px;
}

@media only screen and (max-width: 768px) {
  .sx-separator h1,
  .sx-separator h2,
  .sx-separator h3 {
    font-size: 24px;
    margin-bottom: 0px;
  }

  .sx-separator {
    margin-bottom: 0px;
  }

  .sx-separator-outer.separator-left .sep-line-one {
    padding: 10px 60px 10px 20px;
    font-size: 20px;
    font-weight: 700;
  }

  .sx-separator-outer.separator-right .sep-line-one {
    padding: 10px 20px 10px 60px;
    font-size: 15px;
    font-weight: 700;
  }

  .sx-separator-outer.separator-center .sep-line-one {
    padding: 10px 60px 10px 60px;
    font-size: 15px;
    font-weight: 700;
    right: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .sx-separator-outer.separator-left {
    text-align: left;
  }

  .sx-separator-outer.separator-right {
    text-align: left;
  }

  .sx-separator-outer.separator-center {
    text-align: left;
  }
}

@media only screen and (max-width: 540px) {
  .sx-separator-outer.separator-center .sep-line-one {
    /* padding: 20px 50px 14px 50px; */
    letter-spacing: inherit;
    text-align: center;
  }

  .section-content .service-card-container {
    width: 100% !important;
    transition: all 0.5s ease-in-out;
  }
}

/*-------------------------------------
	Deviders 
-------------------------------------*/
.title-width {
  width: 250px;
}

/* why-choose-container Start*/
.why-choose-container {
  margin: auto;
  background: linear-gradient(
    356deg,
    rgb(240 217 169) 0%,
    rgb(250 242 229) 100%
  );
  padding: 20px;
}
/* why-choose-container End*/

/* faq-container Start*/
.faq-container {
  margin: auto;
  background: linear-gradient(327deg, #eddba2 20%, #d8a652 100%) !important;
  padding: 20px;
}
/* faq-container End*/

@media only screen and (max-width: 640px) {
  .sx-bnr-inr-entry .banner-title-outer {
    margin: 0px;
  }

  .sx-bnr-inr-entry .banner-title-name h1,
  .sx-bnr-inr-entry .banner-title-name h2,
  .sx-bnr-inr-entry .banner-title-name h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .sx-bnr-inr-entry .banner-title-outer p {
    font-size: 10px;
    margin: 0;
  }

  /* Contact Seciton */
  .contact-container {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .card-banner .contact-form-container {
    padding-right: 0px;
    margin: 15px auto !important;
  }

  .card-banner .contact-form,
  .card-banner .contact-form-container {
    width: 100%;
  }

  .card-banner .contact-details {
    width: 100%;
    height: auto !important;
    /* right: 0; */
    margin: auto !important;
  }
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
  color: #fff;
}

/*breadcrumb-row-1*/

.sx-breadcrumb {
  margin: 0;
  list-style: none;
}

.sx-breadcrumb li {
  padding: 0;
  margin-right: 5px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-size: 12px;
}

.sx-breadcrumb li a {
  color: #e3a02c;
}

.sx-breadcrumb li a:hover {
  color: #e3a02c;
}

.sx-breadcrumb li:last-child {
  color: #fff;
}

.sx-breadcrumb li:first-child a i {
  font-size: 18px;
  vertical-align: text-top;
}

.sx-breadcrumb li:last-child:after {
  display: none;
}

/*Breadcrumb-1*/

.breadcrumb-style-2 li:after {
  content: "\f101";
  margin-left: 7px;
  font-family: "FontAwesome";
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}

@media only screen and (max-width: 991px) {
  footer .container {
    width: 100%;
  }

  .sx-footer-bot-left {
    float: none;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.what-we-do-media {
  transition: all 0.8s;
}

.what-we-do-card {
  margin: auto 10px !important;
}

@media only screen and (max-width: 900px) {
  .architect-banner {
    display: none !important;
  }

  .what-we-do-media {
    height: 250px !important;
    width: 250px !important;
    transition: all 0.8s;
  }

  .what-we-do-card {
    align-items: start !important;
    transition: all 0.8s;
  }
}

/* Footer Section end */

@media only screen and (max-width: 500px) {
  .header-style-1 .sx-topbar-left,
  .header-style-1 .sx-topbar-right {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .flip-card-section {
    padding: auto !important;
  }

  .grid-banner {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .pwa-installation .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 10px !important;
    font-size: 13px !important;
    max-width: 400px !important;
    overflow: hidden;
    transition: all 0.8s ease-in-out !important;
  }

  .pwa-model-dim {
    width: 100% !important;
    transition: all 0.8s ease-in-out;
  }
}

/* Book Flip card */
.flip-book-container {
  width: 100%;
  /* max-width: 1400px; */
  margin: 0 auto;
}

.btn-container {
  margin: 5px auto !important;
}

.site-button {
  text-wrap: nowrap;
  padding: 10px;
  background-color: #e3a02c !important;
  color: light !important;
  font-weight: 500 !important;
}

.footer-top .sx-footer-bot-left span {
  margin: auto;
  justify-content: center;
  display: flex;
}

/*Service Section -  Services card animation */
.card-border-animate {
  position: relative;
  width: 450px;
  padding: 10px;
  border: 1px solid #e3a02c;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.8s ease-in-out;
}

.card-border-animate::before,
.card-border-animate::after {
  content: "";
  position: absolute;
  height: 2px;
  background: #e3a02c;
  transition: all 0.3s ease;
}

.card-border-animate::before {
  top: 0;
  left: -100%;
  right: 100%;
}

.card-border-animate::after {
  bottom: 0;
  left: 100%;
  right: -100%;
}

.card-border-animate:hover::before {
  left: 0;
  right: 0;
}

.card-border-animate:hover::after {
  left: 0;
  right: 0;
}

.section-full .read-more {
  border-bottom: 3px solid #e3a02c !important;
  border-radius: 0px;
}

.service-icon-size {
  font-size: 75px !important;
}

.blogs-container .img-banner img {
  transition: all 0.8s;
}

.explore-service-card {
  height: 320px;
}

.experties-font-title {
  /* color: #432223 !important; */
  font-weight: 500 !important;
  text-wrap: nowrap;
}

/* .experties-component-desc {
  color: white !important;
} */

.p-card-header {
  font-weight: 500 !important;
}

/*____Clients logo grid
======================================*/

.client-grid {
  position: relative;
  overflow: hidden;
}

.client-logo-pic {
  text-align: center;
  display: block;
  position: relative;
  overflow: hidden;
  border: 1px solid #ddd;
  background: linear-gradient(181deg, white, #e3a02c);
}

.client-logo-pic * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.client-logo-pic:before,
.client-logo-pic:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  background-color: #eef1f2;
  content: "";
  opacity: 0.9;
  z-index: 1;
}

.client-logo-pic:before {
  -webkit-transform: skew(45deg) translateX(-155%);
  transform: skew(45deg) translateX(-155%);
}

.client-logo-pic:after {
  -webkit-transform: skew(45deg) translateX(155%);
  transform: skew(45deg) translateX(155%);
}

.client-logo-pic img {
  backface-visibility: hidden;
  max-width: 100%;
  vertical-align: top;
}

.client-logo-pic div {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%) scale(0.5);
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  -webkit-box-shadow: 0 0 10px #000000;
  box-shadow: 0 0 10px #000000;
}

.client-logo-pic span {
  background-color: #000000;
  color: #fff;
  font-size: 12px;
  letter-spacing: 4px;
  margin: 0;
  padding: 5px 10px;
  white-space: nowrap;
}

.client-logo-pic:hover > img {
  opacity: 0.5;
}

.client-logo-pic:hover:before {
  -webkit-transform: skew(45deg) translateX(-55%);
  transform: skew(45deg) translateX(-55%);
}

.client-logo-pic:hover:after {
  -webkit-transform: skew(45deg) translateX(55%);
  transform: skew(45deg) translateX(55%);
}

.client-logo-pic:hover div {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

.client-logo-media + .tooltip {
  bottom: auto !important;
  top: 50px !important;
  z-index: 10;
}

.section-content .service-count {
  margin-top: -50px;
  transition: all 0.5s;
}

.section-content .service-card-container {
  width: 300px;
  transition: all 0.5s ease-in-out;
}

.section-content .service-card-container:hover .service-count {
  margin-top: 5px;
  transition: all 0.5s ease-in-out;
}

.container-bg {
  background-color: #eef1f2;
}

.img-banner {
  max-width: 80%;
}

.top-blog-service .blog-social-icon {
  font-size: 50px;
  margin: 5px;
}

/* WhatsAap redirect/Contact us start */
.rediret-wa {
  position: fixed;
  bottom: 15px;
  right: 5px;
  z-index: 99;
  outline: none;
  user-select: none;
}

.whatsaap-radius {
  border-radius: 30px 30px;
}

.whatsapp-breadcrumb {
  padding: 8px 30px;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
}

.whatsapp-breadcrumb p {
  padding: 0;
  margin: 0;
  font-weight: 600;
  width: fit-content;
}

.wa-now {
  position: absolute;
  border-radius: 50%;
  z-index: 99;
  width: 45px;
  height: 45px;
  border: 2px solid #3db84d;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  transform-origin: center center;
  outline: none;
  user-select: none;
  margin-left: 5px;
  margin-top: 3px;
}

.wa-now img {
  height: 30px;
  width: 30px;
  border-radius: 50% !important;
}
/* WhatsAap redirect/Contact us end */

.css-1vafv2a-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
  /* background-color: white !important;
  padding: 2px !important; */
}

.css-76wzg2-MuiInputBase-root-MuiOutlinedInput-root,
.css-i2dzgj-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: rgb(246, 243, 243);
  padding: 0px;
}

.css-exx25z-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-exx25z-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: black !important;
}

/* sidebar data start */
.polygon {
  transform: skew(-10deg);
  border-left: 5px solid #e3a02c;
  border-bottom: 5px solid #e3a02c;
}

.sidebar-icon {
  color: #e3a02c;
  font-weight: bold;
}

.sidebar-sub-icon {
  color: rgb(211 176 116);
  font-weight: bold;
  margin-left: 10px;
}

/* sidebar data end */

/* Theme color Start*/
.bg-theme-color {
  background-color: #2f181a !important;
  padding-bottom: 10px;
}

.text-theme-color {
  color: #2f181a !important;
}

.bg-color {
  background-color: #2f181a !important;
  padding-bottom: 10px;
}

.text-color {
  color: #2f181a !important;
}

.bg-theme-color-light {
  background-color: #e3a02c !important;
}

.pwa-bg-theme-file {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pwa-bg-theme-color-light {
  background-color: #ecd2a6 !important;
  width: fit-content;
  padding: 5px !important;
}

.pwa-model-dim {
  width: 500px;
  transition: all 0.8s ease-in-out;
}

.linear-gradiant-theme {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    355deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 243, 210, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );
}

.text-theme-color-light {
  color: #e3a02c !important;
}

.career-input .css-e70qmq .MuiTextField-root,
.career-input .css-1xh3tvb-MuiFormControl-root {
  margin: 8px auto !important;
}

.whatsaap-bg-theme {
  background-color: #ffd181 !important;
}

.border-color {
  border: 2px solid #ffd181 !important;
  /* border: 2px solid #ffd997 !important; */
}
/* Theme color end */

.project-menu-btn {
  color: black !important;
  margin: 5px !important;
}

.loader-container {
  z-index: 3000 !important;
}

.experties-font-title {
  font-size: 17px !important;
}

.why-choose-container .card-skew {
  width: 340px; /* Adjust as needed */
  border-top-left-radius: 48%;
  border-top-right-radius: 48%;
  background: linear-gradient(90deg, #e2c465 20%, #e3a02c 100%) !important;
}

.career-title {
  font-size: 25px;
}

.career-title-child {
  width: fit-content;
}

.career-btn-container .career-btn {
  padding: 8px;
  border: 2px solid #e3a02c;
  border-radius: 30px;
  margin: auto;
  text-transform: capitalize;
}

.career-btn-container .career-btn:hover {
  color: white !important;
  background-color: #e3a02c;
  box-shadow: #eacc98;
  transition: all ease-in-out 0.8s;
}

.career-card-container {
  transition: all 0.8s ease-in-out;
  border: 2px solid transparent;
}

.career-card-container:hover {
  border: 2px solid #e3a02c;
  transition: all 0.8s ease-in-out;
}

.horizontol-line {
  background: linear-gradient(
    81deg,
    rgba(240, 205, 110, 1) 23%,
    rgba(244, 242, 222, 1) 81%,
    rgba(244, 242, 222, 1) 100%
  );
  width: 150px;
  height: 3px;
}

.card-skew-content {
  height: 150px;
}

/* Input field outline ko maintain karna */
/* .MuiInputBase-root input:focus {
  outline: 2px solid #3f51b5;
  border: none;
} */

.service-slider-text-box {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.gradiant-fade-animate {
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: bgAnimate 8s reverse infinite;
  position: absolute;
  height: 100%;
  width: 100%;
  /* background: linear-gradient(290deg, #e2c465 20%, #e3a02c 100%); */
  background: linear-gradient(
    329deg,
    rgba(255, 255, 255, 1) 37%,
    rgba(219, 219, 219, 1) 51%,
    rgba(255, 255, 255, 1) 67%
  );
  opacity: 0.4;
}

.css-1lt6jif.Mui-focused,
.css-pptsn5.Mui-checked,
.css-pptsn5.MuiCheckbox-indeterminate {
  color: black !important;
}

input:focus + label {
  color: black !important; /* Focus hone par label ka color white hota hai */
}

.css-1u2w381-MuiModal-root-MuiDrawer-root {
  z-index: 1100 !important;
}

.MuiOutlinedInput-root .fieldset {
  border-color: #000 !important;
}

.css-1vafv2a-MuiFormLabel-root-MuiInputLabel-root,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  background-color: white !important;
  padding: 2px !important;
}

.css-1odjkod-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  margin-top: 2px;
}

.css-hz1bth-MuiDialog-container,
.css-ekeie0 {
  height: auto !important;
}

.verify-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 400px !important;
}

.MuiDataGrid-cell--textLeft {
  display: flex !important;
  align-items: center !important;
}

@keyframes bgAnimate {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-150%);
  }
}

.slick-slide,
.slider-container {
  padding: 10px; /* Adjust the horizontal margin as needed */
}

/* Blogs search input */
.blog-info-container .search-bg,
.search-bg .css-i2dzgj-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #faba00;
  /* color: #e3a02c; */
  border-radius: 20px;
  outline: none !important;
  border: none !important;
  width: 100%;
  color: black;
}

.blog_right_sidebar {
  border: 1px solid #eeeeee;
  background: #fafaff;
  padding: 10px;
}

.blog-info-container .blog-info-head {
  background-color: #faba00;
  color: white;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 2px;
  text-align: center;
  margin: 25px auto;
}

.blog-info-container .user-avatar {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.blog-info-container .social-icon a {
  text-decoration: none;
  cursor: pointer;
  margin: auto 5px;
}

.blog-info-card p {
  text-align: center;
}

.popular-post-card .popular-post-details {
  margin: 5px auto;
}

.popular-post-details img {
  height: 60px;
  width: 100px;
  margin-right: 10px !important;
}

.post-info-title {
  font-size: 12px;
  text-transform: capitalize;
  text-align: start;
}

.post-info-title b {
  transition: all 0.8s ease-in-out;
}

.post-info-title:hover b {
  color: #faba00;
  transition: all 0.8s ease-in-out;
}

.post-info-category {
  transition: all 0.8s ease-in-out;
  text-transform: capitalize;
  border-bottom: 1px dotted lightgrey;
}

.post-info-category:hover {
  color: #faba00;
  border-bottom: 1px dotted #faba00;
  transition: all 0.8s ease-in-out;
  text-transform: capitalize;
}

/* .MuiDataGrid-cell--textLeft {
  text-wrap: wrap !important;
  line-height: 1.5 !important;
  overflow: auto !important;
} */

.css-1l1167e {
  height: 66px !important;
  width: 66px !important;
  margin: auto !important;
}

@media only screen and (max-width: 600px) {
  .card-banner .css-3f8xas-MuiPaper-root-MuiCard-root,
  .card-banner .css-1bpd7rf-MuiPaper-root-MuiCard-root,
  .card-banner .contact-card {
    width: 100% !important;
    margin: auto;
    padding: 5px auto;
  }

  .experties-font {
    font-size: 12px !important;
  }

  .team-container .team-card {
    width: 100% !important;
  }

  .logo-header-inner img {
    width: 70px;
    height: 70px;
    margin: 5px;
  }

  /* .home-caraousal-banner {
    margin-top: 82;
  } */

  .footer-top .sx-footer-bot-left span {
    display: block;
  }

  .blogs-container .img-banner .top-img {
    transition: all 0.8s;
    width: 100%;
  }

  .comments-components {
    flex-direction: column;
    transition: all 0.8s;
    margin-left: 0px !important;
  }

  .comments-components img {
    transition: all 0.8s;
    margin: auto;
  }

  .what-we-do-media {
    height: 100px !important;
    width: 100px !important;
    transition: all 0.8s;
  }

  .what-we-do-card {
    margin: initial !important;
    padding: initial !important;
  }

  .what-we-do-card .text-black,
  .text-theme-color {
    font-size: 12px !important;
    align-items: start !important;
    margin: auto 10px !important;
  }

  .text-theme-color-bold {
    font-weight: bold !important;
    font-size: 18px !important;
  }

  .card-banner .what-we-do-card {
    margin: 0px 10px !important;
  }

  .pwa-model-dim {
    max-width: 400px;
    transition: all 0.8s ease-in-out;
  }
}

@media only screen and (max-width: 767px) {
  .client-grid.m-b40 {
    margin-bottom: 0px;
  }

  .img-banner {
    max-width: 100%;
  }

  .card-border-animate {
    margin: auto;
    width: 100% !important;
    transition: all 0.8s ease-in-out;
  }
}

@media only screen and (max-width: 480px) {
  .client-logo-pic {
    padding: 10px;
  }

  .experties-component {
    width: 200px;
    padding: 0px;
  }
  .experties-component-desc {
    display: none;
  }

  .card-banner .input-f-width {
    width: 100% !important;
  }

  .card-banner .what-we-do-card {
    width: 300px;
  }

  .experties-font-title {
    font-size: 12px !important;
  }

  .experties-component {
    height: auto;
  }
}

@media only screen and (max-width: 400px) {
  .experties-component {
    width: 165px;
    padding: 0px;
  }

  .experties-component-desc {
    display: none;
  }

  .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root {
    padding: 5px !important;
  }

  .css-1lvztma-MuiButtonBase-root-MuiButton-root {
    padding: 5px !important;
    min-width: 40px !important;
  }

  .verify-container .react-tel-input .form-control {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .flip-book-container {
    /* max-height: 60vh; */
    /* Adjust height for smaller screens */
    align-items: start;
    justify-content: center;
    display: flex;
    margin: auto 0px;
  }

  .aon-thum-bx img {
    height: 190px;
  }

  .card-banner .contact-form-container .contact-h4 {
    font-size: 18px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .quick-contact-nav {
    display: block;
  }

  .contact-toolbar {
    display: none !important;
  }

  .contact-toolbar-icons {
    display: block !important;
  }
}

@media only screen and (max-width: 1450px) {
  .card-border-animate {
    width: 370px;
    transition: all 0.8s ease-in-out;
  }
}

@media only screen and (max-width: 1200px) {
  .footer-col-3 {
    clear: both;
  }

  .card-border-animate {
    width: 500px;
    transition: all 0.8s ease-in-out;
  }
}

@media only screen and (max-width: 1070px) {
  .login-banner {
    display: none;
    transition: all 0.8s ease-in-out;
  }

  .page-notfound img {
    width: 75% !important;
  }

  .card-border-animate {
    transition: all 0.8s ease-in-out;
    width: 350px;
  }
}
